import React from "react";
import ModalSelectDate from "components/SearchSelect/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

interface MobileFooterStickyProps {
  experience: {
    id: string;
    title: string;
    location: string;
    price: number;
    experience_pickups?: { pickup_area: string; price: number }[]; // Tambahkan properti ini
    imageUrl: string;
    experience_type_id: number; // To check if it's per guest or group
    experience_medias?: { media: { path: string } }[];
    max_participant_count: number; // Tambahkan max_participant_count
    times_available_string?: string; // Add optional field for available times
  };
  currency: string;
  guests: number;
  convertedPrice: number;
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  onGuestsChange: (guests: number) => void;
  onCurrencyChange?: (currency: string) => void; // Buat opsional dengan tanda "?"
  selectedTime: string; // New prop for selected time
  onTimeChange: (time: string) => void; // New prop for handling time change
}

const MobileFooterSticky: React.FC<MobileFooterStickyProps> = ({
  experience,
  currency,
  guests,
  convertedPrice,
  selectedDate,
  selectedTime, // New prop for selected time
  onDateChange,
  onGuestsChange,
  onTimeChange, // New handler for time change
  onCurrencyChange,
}) => {
  const navigate = useNavigate();

  // Handle for reserve button click
  const handleReserveClick = () => {
    if (!selectedDate) {
      alert("Please select a date before proceeding.");
      return;
    }
  
    if (!selectedTime) {
      alert("Please select a time before proceeding.");
      return;
    }
  
    if (experience.experience_pickups && experience.experience_pickups.length > 0) {
      // Simpan data ke sessionStorage
      const checkoutData = {
        experiencePickup: experience.experience_pickups,
        experienceId: experience.id,
        experienceTitle: experience.title,
        experienceLocation: experience.location,
        price: convertedPrice,
        currency: currency,
        guests: guests,
        date: selectedDate,
        time: selectedTime, // Include selected time
        mainImage: experience.experience_medias?.[0]?.media?.path || experience.imageUrl,
      };
      
      sessionStorage.setItem("checkoutData", JSON.stringify(checkoutData));
      console.log("Data stored in sessionStorage:", checkoutData);
      
      // Navigasi ke halaman checkout
      navigate(`/checkout?experiencePickup=${encodeURIComponent(JSON.stringify(experience.experience_pickups))}`);
    } else {
      console.error("experience.experience_pickups is empty or undefined.");
    }
  };
  

  const formatPrice = (price: number, currency: string) => {
    if (currency === "IDR") {
      return price.toLocaleString("id-ID");
    } else {
      return price.toFixed(2);
    }
  };

  const calculateTotalPrice = () => {
    if (experience.experience_type_id === 1) {
      return formatPrice(experience.price * guests, currency);
    } else {
      return formatPrice(experience.price, currency);
    }
  };

  // Increment function, checking max_participant_count
  const handleIncrement = () => {
    if (guests < experience.max_participant_count) {
      onGuestsChange(guests + 1);
    } else {
      alert(`Maximum number of participants is ${experience.max_participant_count}`);
    }
  };

  // Decrement function, ensuring minimum 1 guest
  const handleDecrement = () => {
    if (guests > 1) {
      onGuestsChange(guests - 1);
    }
  };

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div>
          <span className="block text-xl font-semibold">
            {currency} {calculateTotalPrice()}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              {experience.experience_type_id === 1 ? "/Person" : "/Group"}
            </span>
          </span>
          <div className="flex items-center space-x-4">
            {/* Select Date */}
            <ModalSelectDate
              onDateChange={(selectedDate) => onDateChange(selectedDate)}
              renderChildren={({ openModal }) => (
                <span onClick={openModal} className="block text-sm underline font-medium">
                  {converSelectedDateToString(selectedDate) || "Select date"}
                </span>
              )}
            />

            <div className="flex items-center space-x-2 ">
              <div className="relative w-full">
                <select
                  id="time"
                  className="w-full border border-neutral-200 dark:border-neutral-700 rounded-lg px-4 py-2 pr-8 appearance-none" // Tambahkan padding kanan
                  onChange={(e) => onTimeChange(e.target.value)} // Time change handler
                  value={selectedTime}
                >
                  <option value="" disabled>Time</option>
                  {experience.times_available_string?.split("__").map((time: string) => (
                    <option key={time} value={time}>{time}</option>
                  ))}
                </select>
                {/* Tambahkan icon dropdown kustom (opsional) */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Guests input */}
            <div className="flex items-center space-x-2">
              <button
                className="px-2 py-1 bg-neutral-200 dark:bg-neutral-700 rounded text-sm"
                onClick={handleDecrement}
              >
                -
              </button>
              <span className="text-sm font-medium text-neutral-900 dark:text-neutral-200 w-8 text-center">
                {guests}
              </span>
              <button
                className="px-2 py-1 bg-neutral-200 dark:bg-neutral-700 rounded text-sm"
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <ButtonPrimary onClick={handleReserveClick}>Reserve</ButtonPrimary>
      </div>
    </div>
  );
};


export default MobileFooterSticky;
