import React, { FC, useState, useEffect, Fragment, useRef } from "react";
import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import NcImage from "shared/NcImage/NcImage";
import ModalSelectDate from "components/ModalSelectGuests";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import ButtonPrimary from "shared/Button/ButtonPrimary"; 
import cartService from "services/cartService";
import experienceService from "services/experienceService";
import { time } from "console";

const libraries: ("places")[] = ["places"];


// Deklarasi interface PickupArea di sini
interface PickupArea {
  pickup_area: string;
  price: number;
}

const CheckOutPage: FC = () => {
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("Cash");
  const [pickupAddress, setPickupAddress] = useState("");
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [locationState, setLocationState] = useState<any>(null); // Initial state set to null
  const [whatsapp, setWhatsapp] = useState<string>("");
  const [selectedPickupPrice, setSelectedPickupPrice] = useState(0);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [differentHotel, setDifferentHotel] = useState(false);
  const [dropoffAddress, setDropoffAddress] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true); // Tambahkan state untuk loading data


 // Load Google Maps API
 const { isLoaded } = useLoadScript({
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  libraries,
});

const pickupRef = useRef<google.maps.places.SearchBox | null>(null);
const dropoffRef = useRef<google.maps.places.SearchBox | null>(null);

// Ambil data dari sessionStorage saat komponen dimuat
useEffect(() => {
  try {
    const sessionData = sessionStorage.getItem("checkoutData");
    if (sessionData) {
      const parsedData = JSON.parse(sessionData);
      console.log("Data dari sessionStorage:", parsedData);
      setLocationState(parsedData);
    } else {
      console.warn("Tidak ada data di sessionStorage.");
    }
  } catch (error) {
    console.error("Gagal mem-parsing data dari sessionStorage:", error);
  }
}, []);


  // Tampilkan "Loading data..." saat data belum diambil dari sessionStorage
  if (!locationState || Object.keys(locationState).length === 0) {
    console.log("locationState is empty or null");
    return <div>Loading data...</div>;
  }


const {
  experiencePickup = [],
  experienceTitle = "",
  experienceLocation = "",
  price = 0,
  currency = "IDR",
  time = "",
  guests = 1,
  date = "",
  mainImage = "",
} = locationState;

// Validasi form
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const whatsappRegex = /^\+?[1-9]\d{1,14}$/;

const validateForm = () => {
  const isValid = emailRegex.test(email) && whatsappRegex.test(whatsapp) && name.trim() !== "";
  setIsFormValid(isValid);
};

const handlePickupChange = (price: number) => {
  setSelectedPickupPrice(price);
};

const handleConfirm = async () => {
  if (!locationState) return; // Ensure locationState is not null

  const {
    experienceTitle,
    date,
    time,
    guests,
    currency,
    mainImage,
    experienceId,
  } = locationState;

  const actualPrice = typeof locationState.price === 'string' ? Number(locationState.price.replace(/[.,]/g, '')) : locationState.price;

  const cartData = {
    bookingDetails: {
      experience_id: experienceId,
      experienceTitle: experienceTitle,
      name: name,
      date: date,
      time: time,
      whatsapp: whatsapp,
      location: experienceLocation,
      email: email,
      person: guests.toString(),
      dropoff_place: dropoffAddress,
      pickup_place: pickupAddress,
      total_price: (actualPrice + selectedPickupPrice).toString(),
      currency_choosed: currency,
      payment_method: selectedPaymentMethod,
      status: "Not Confirmed",
  },
    userEmail: email,
  };

  setIsLoading(true);

  try {
    await experienceService.confirmBooking(cartData);
    await cartService.addItem(cartData);
    navigate("/pay-done", {
      state: {
        experienceTitle,
        mainImage: mainImage || "",
        guests,
        date,
        time,
        bookingCode: "222-333-111",
        totalPrice: actualPrice + selectedPickupPrice,
        paymentMethod: selectedPaymentMethod,
      },
    });
  } catch (error) {
    console.error("Failed to add item to cart:", error);
  } finally {
    setIsLoading(false);
  }
};

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const actualPrice = typeof price === 'string' ? Number(price.replace(/[.,]/g, '')) : price;
const formattedDate = date ? new Date(date).toLocaleDateString() : "Invalid date";

  const renderDetails = () => (
    <div className="w-full flex flex-col rounded-2xl border border-neutral-200 dark:border-neutral-700 space-y-6 p-6">
      <div className="flex flex-col sm:flex-row sm:items-center">
        <div className="flex-shrink-0 w-full sm:w-40">
          <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
          <NcImage src={mainImage || "https://via.placeholder.com/400"} />
          </div>
        </div>
        <div className="py-5 sm:px-5 space-y-3">
          <div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
              {experienceLocation}
            </span>
            <span className="text-base font-medium mt-1 block">
              {experienceTitle}
            </span>
          </div>
          <span className="block text-sm text-neutral-500 dark:text-neutral-400">
            {guests} guests
          </span>
          <span className="block text-sm text-neutral-500 dark:text-neutral-400">
            Date: {formattedDate}
          </span>
          <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
          <StartRating />
        </div>
      </div>
      <div className="flex flex-col space-y-4">
            <h3 className="text-2xl font-semibold">Price detail</h3>
            

            <div className="flex justify-between text-neutral-600 dark:text-neutral-300">
              <span>{currency} {actualPrice.toLocaleString()}</span>
              <span>{currency} {actualPrice.toLocaleString()}</span>
              </div>

            {selectedPickupPrice > 0 && (
                <div className="flex justify-between text-neutral-600 dark:text-neutral-300">
                  <span>Pickup Price</span>
                  <span>{currency} {selectedPickupPrice.toLocaleString()}</span>
                </div>
              )}

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>{currency} {(actualPrice + selectedPickupPrice).toLocaleString()}</span>
              </div>

          </div>

    </div>
  );
  

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div>
          <div>
            {renderDetails()}
          </div>
        </div>

        {/* Section untuk Traveler Data */}
        <div>
            <h3 className="text-2xl font-semibold">Traveler Data</h3>
            <div className="mt-6 space-y-4">
              <div className="space-y-1">
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateForm();
                  }}
                />
                {email && !emailRegex.test(email) && (
                  <span className="text-red-500 text-sm">Invalid email format</span>
                )}
              </div>
              <div className="space-y-1">
                <Label>Name</Label>
                <Input
                  type="text"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    validateForm();
                  }}
                />
              </div>
              <div className="space-y-1">
                <Label>WhatsApp Number</Label>
                <Input
                  type="text"
                  placeholder="Enter your WhatsApp number (e.g., +628123456789)"
                  value={whatsapp}
                  onChange={(e) => {
                    setWhatsapp(e.target.value);
                    validateForm();
                  }}
                />
                {whatsapp && !whatsappRegex.test(whatsapp) && (
                  <span className="text-red-500 text-sm">Invalid WhatsApp format</span>
                )}
              </div>
            </div>
        </div>

        <div>
            <h3 className="text-2xl font-semibold">Meeting Point or Pickup</h3>
            <div className="mt-6 space-y-4">
              <div className="space-y-1">
                <Label>Meeting Point</Label>
                <div className="div mb-3">
                  <p className="m-3 text-sm font-normal">If you want to come with own transport, please come to this place at time chosen.</p>
                </div>
                <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
                  <div className="rounded-xl overflow-hidden z-0">
                    <iframe
                      title="x"
                      width="100%"
                      height="100%"
                      loading="lazy"
                      allowFullScreen
                      referrerPolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${experienceLocation}`}
                    ></iframe>
                  </div>
                </div>
              </div>

              
              <div className="space-y-4">
                <Label>Pickup and Drop Off</Label>
                <div className="space-y-3">
                  <p className="text-base font-medium">Price for pickup and drop-off</p>
                  <p className="text-sm font-normal">
                    If you are interested in pickup and drop-off, please check the prices and input your hotel below.
                  </p>
                  <div className="space-y-2">
                  {experiencePickup && experiencePickup.length > 0 ? (
                      experiencePickup.map((pickup: PickupArea, index: number) => (
                        <div key={index} className="flex items-center space-x-4 text-sm text-neutral-700 dark:text-neutral-300">
                          <input
                            type="radio"
                            name="pickup"
                            id={`pickup-${index}`}
                            className="w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                            onChange={() => handlePickupChange(pickup.price)} 
                          />
                          <label htmlFor={`pickup-${index}`} className="font-regular text-lg">
                            {pickup.pickup_area}: {currency} {pickup.price}
                          </label>
                        </div>
                      ))
                    ) : (
                      <p className="text-sm text-neutral-500">No pickup areas available for this experience.</p>
                    )}
                  </div>
                </div>

                {/* Pickup Address Input */}
                <div className="w-full flex flex-col space-y-2">
                  {isLoaded && (
                    <StandaloneSearchBox
                      onLoad={(ref) => (pickupRef.current = ref)}
                      onPlacesChanged={() => {
                        const places = pickupRef.current?.getPlaces();
                        if (places && places.length > 0 && places[0].name) {
                          setPickupAddress(places[0].name);
                        }
                      }}
                    >
                      <div className="w-full">
                        <input
                          type="text"
                          placeholder="Type the hotel name here & choose from the list"
                          className="border p-2 rounded w-full"
                          style={{ boxSizing: "border-box" }}
                          value={pickupAddress}
                          onChange={(e) => setPickupAddress(e.target.value)}
                        />
                      </div>
                    </StandaloneSearchBox>
                  )}
                </div>

                {/* Checkbox untuk Drop Off di hotel berbeda */}
                <div className="flex items-center mt-4">
                  <input
                    id="different-hotel"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    onChange={(e) => setDifferentHotel(e.target.checked)}
                  />
                  <label
                    htmlFor="different-hotel"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Do you want to drop off in a different hotel?
                  </label>
                </div>

                {/* Input untuk Drop Off di Hotel Berbeda */}
                {differentHotel && (
                  <div className="w-full flex flex-col space-y-2 mt-4">
                    {isLoaded && (
                      <StandaloneSearchBox
                        onLoad={(ref) => (dropoffRef.current = ref)}
                        onPlacesChanged={() => {
                          const places = dropoffRef.current?.getPlaces();
                          if (places && places.length > 0 && places[0].name) {
                            setDropoffAddress(places[0].name);
                          }
                        }}
                      >
                        <div className="w-full">
                          <input
                            type="text"
                            placeholder="Type the drop-off hotel name here & choose from the list"
                            className="border p-2 rounded w-full"
                            style={{ boxSizing: "border-box" }}
                            value={dropoffAddress}
                            onChange={(e) => setDropoffAddress(e.target.value)}
                          />
                        </div>
                      </StandaloneSearchBox>
                    )}
                  </div>
                )}
              </div>
                  

            </div>
          </div>


        <div>
          <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
          <Tab.Group
        onChange={(index) => {
          // Update payment method based on the selected tab
          const methods = ["Cash", "Paypal", "Credit Card"];
          setSelectedPaymentMethod(methods[index]);
        }}
      >
        <Tab.List className="flex my-5 gap-1">
          <Tab as="button">
            {({ selected }) => (
              <button
                className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full ${
                  selected ? "bg-neutral-800 text-white" : "text-neutral-600"
                }`}
              >
                Cash
              </button>
            )}
          </Tab>
          <Tab as="button">
            {({ selected }) => (
              <button
                className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full ${
                  selected ? "bg-neutral-800 text-white" : "text-neutral-600"
                }`}
              >
                Paypal
              </button>
            )}
          </Tab>
          <Tab as="button">
            {({ selected }) => (
              <button
                className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full flex items-center ${
                  selected ? "bg-neutral-800 text-white" : "text-neutral-600"
                }`}
              >
                <span className="mr-2">Credit card</span>
                <img className="w-8" src={visaPng} alt="visa" />
                <img className="w-8" src={mastercardPng} alt="mastercard" />
              </button>
            )}
          </Tab>
        </Tab.List>

        <Tab.Panels>
          {/* Cash Panel */}
          <Tab.Panel className="space-y-5">
            <div className="flex justify-between text-neutral-600 dark:text-neutral-300">
              <span>Original Price</span>
              <span>{currency} {actualPrice.toLocaleString()}</span>
            </div>
            {selectedPickupPrice > 0 && (
              <div className="flex justify-between text-neutral-600 dark:text-neutral-300">
                <span>Pickup Price</span>
                <span>{currency} {selectedPickupPrice.toLocaleString()}</span>
              </div>
            )}
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>{currency} {(actualPrice + selectedPickupPrice).toLocaleString()}</span>
            </div>
          </Tab.Panel>

             {/* Paypal */}
             <Tab.Panel className="relative space-y-5">
                  {/* Overlay untuk Maintenance dengan Rounded Corners */}
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 rounded-lg">
                    <span className="text-white text-lg font-semibold">Maintenance</span>
                  </div>
                  
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" placeholder="Enter your email" disabled />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" placeholder="Enter your password" disabled />
                  </div>
                  <div className="space-y-1">
                    <Label>Message for author </Label>
                    <Textarea placeholder="Write a few sentences about yourself..." disabled />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>

                {/* Credit Card */}
                <Tab.Panel className="relative space-y-5">
                  {/* Overlay untuk Maintenance dengan Rounded Corners */}
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 rounded-lg">
                    <span className="text-white text-lg font-semibold">Maintenance</span>
                  </div>
                  
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input placeholder="Enter your card number" disabled />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input placeholder="Enter card holder name" disabled />
                  </div>
                  <div className="flex space-x-5">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="text" placeholder="MM/YY" disabled />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input placeholder="Enter CVC" disabled />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Message for author </Label>
                    <Textarea placeholder="Write a few sentences about yourself..." disabled />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="pt-8">
      <ButtonPrimary onClick={handleConfirm} disabled={!isFormValid || isLoading}>
        {isLoading ? (
          <div className="flex items-center justify-center">
            {/* Spinner atau animasi loading */}
            <svg
              className="animate-spin h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            Processing...
          </div>
        ) : (
          'Confirm'
        )}
      </ButtonPrimary>
      {!isFormValid && (
        <span className="text-red-500 text-sm">Please fill all fields correctly</span>
      )}
    </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col space-y-8">
        <div className="w-full">
          {renderMain()}
        </div>
      </main>
    </div>
  );
};

export default CheckOutPage;